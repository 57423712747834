import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import logo from "../assets/logo.webp";

const Header = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setIsVisible(false); // Oculta el header si el usuario baja
    } else {
      setIsVisible(true); // Muestra el header si el usuario sube
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <motion.header
      className={`fixed top-0 left-0 w-full z-50 py-4 px-4 ${
        isVisible ? "opacity-100" : "opacity-0"
      } transition-opacity duration-500 bg-transparent`}
    >
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <img src={logo} alt="Finca Lopez Logo" className="h-28" />
        {/* Navegación */}
        <nav>
          <ul className="flex space-x-4 text-white">
            {["Inicio", "Sobre Nosotros", "Galería", "Contacto"].map(
              (item, index) => (
                <li key={index} className="cursor-pointer">
                  <a
                    href={`#${item.toLowerCase().replace(" ", "-")}`}
                    className="hover:underline"
                  >
                    {item}
                  </a>
                </li>
              )
            )}
          </ul>
        </nav>
      </div>
    </motion.header>
  );
};

export default Header;
