import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import AboutUs from "./components/AboutUs";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ScrollToTopButton from "./components/ScrollToTopButton";
import { Helmet } from "react-helmet-async";
import { HelmetProvider } from "react-helmet-async";
function App() {
  return (
    
    <div className="font-sans bg-gray-50">
      <React.StrictMode>
      <HelmetProvider>
   
        <title>Finca Lopez | Productores de remolachas en Mendoza</title>
        <meta
          name="description"
          content="Finca Lopez se dedica a la producción de remolachas frescas y de alta calidad en Mendoza, Argentina."
        />
        <meta name="keywords" content="Finca Lopez, agricultura, Mendoza, remolachas frescas, lechuga, lechugas frescas, remolacha, remolachas" />
        <meta name="author" content="Finca Lopez" />
      
      <Header />
      <Hero />
      <AboutUs />
      <Gallery />
      <Contact />
      <Footer />
      <ScrollToTopButton />

      </HelmetProvider>
      </React.StrictMode>
    </div>
  );
}

export default App;
