import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const images = [
    `${require('../assets/foto1.webp')}`,
    `${require('../assets/foto2.webp')}`,
    `${require('../assets/foto3.webp')}`,
    `${require('../assets/foto4.webp')}`,
    `${require('../assets/foto5.webp')}`,
    `${require('../assets/foto6.webp')}`,
    `${require('../assets/foto7.webp')}`,
    `${require('../assets/foto8.webp')}`,
    `${require('../assets/foto9.webp')}`,
    `${require('../assets/foto10.webp')}`,
    `${require('../assets/foto11.webp')}`,
    `${require('../assets/foto12.webp')}`,
    `${require('../assets/foto13.webp')}`,

  ];

const Gallery = () => {
  return (
    <section id="galeria" className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6">Galería</h2>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          loop={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          spaceBetween={30}
          className="w-full max-w-5xl mx-auto"
        >
          {images.map((src, index) => (
            <SwiperSlide
              key={index}
              className="flex justify-center items-center"
              style={{ maxWidth: "80%" }}
            >
              <img
                src={src}
                alt={`Imagen ${index + 1}`}
                className="rounded-xl shadow-lg object-cover h-96 w-full"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Gallery;
