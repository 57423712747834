import React from "react";
import { motion } from "framer-motion";

const AboutUs = () => {
  return (
    <motion.section
      id="sobre-nosotros"
      className="py-12 bg-white text-gray-800"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6">Sobre Nosotros</h2>
        <p className="text-center max-w-2xl mx-auto">
          Nuestra finca, ubicada en el corazón de Mendoza, se dedica a la
          producción de verduras y hortalizas frescas y de alta calidad principalmente remolachas, respetando el
          medio ambiente y promoviendo prácticas agrícolas sostenibles.
        </p>
      </div>
    </motion.section>
  );
};

export default AboutUs;
