import React, { useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_s9unh8j", // Reemplaza con tu Service ID de EmailJS
        "template_q769ab8", // Reemplaza con tu Template ID
        formData,
        "HOu5vIsMCeTUQLDvO" // Reemplaza con tu User ID de EmailJS
      )
      .then(
        () => {
          setIsSent(true);
          setFormData({ name: "", email: "", message: "" });
        },
        (error) => {
          console.error("Error al enviar el correo:", error);
        }
      );
  };

  return (
    <section id="contacto" className="py-12 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6">Contacto</h2>
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Nombre</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 rounded-lg px-4 py-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Correo</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 rounded-lg px-4 py-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Mensaje</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 rounded-lg px-4 py-2"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-green-600 text-white rounded-lg px-4 py-2"
          >
            Enviar
          </button>
        </form>
        {isSent && (
          <p className="text-green-600 text-center mt-4">
            ¡Mensaje enviado con éxito!
          </p>
        )}
      </div>
    </section>
  );
};

export default Contact;
