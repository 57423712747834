import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true); // Muestra el botón si el usuario baja
    } else {
      setIsVisible(false); // Oculta el botón si el usuario está en la parte superior
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Lleva al usuario al inicio
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <motion.div
      className="fixed bottom-8 right-8 z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: isVisible ? 1 : 0 }}
      transition={{ duration: 0.5 }}
    >
      <button
        onClick={scrollToTop}
        className="bg-green-600 text-white p-3 rounded-full shadow-lg hover:bg-green-700 focus:outline-none"
      >
        ⬆️
      </button>
    </motion.div>
  );
};

export default ScrollToTopButton;
