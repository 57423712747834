import React from "react";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <section
      id="inicio"
      className="relative h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${require('../assets/foto13.webp')})` }}
    >
      <div className="bg-black bg-opacity-50 h-full flex flex-col items-center justify-center">
        {/* Texto Principal */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-center text-white px-4"
        >
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            Bienvenidos a Finca Lopez
          </h1>
          <p className="text-lg md:text-xl">
            Produciendo verduras y hortalizas frescas desde Mendoza, Argentina.
          </p>
        </motion.div>

        {/* Flecha Animada */}
        <motion.div
          className="absolute bottom-10 flex flex-col items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 1 }}
        >
          <p className="text-white mb-2 text-sm">Desplázate hacia abajo</p>
          <motion.div
            className="text-white"
            animate={{ y: [0, 10, 0] }}
            transition={{
              repeat: Infinity,
              duration: 1.5,
              ease: "easeInOut",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
